import React, { useState, useEffect, useContext } from "react";
import "../../assets/style/globalStyle.scss";
import { navigate } from "gatsby";
import useArticles, {
  getAllArticles,
  getArticleById,
} from "../../services/articleService";
import { GlobalDispatchContext } from "../../context/GlobalContextProvider";
import CardArticle from "../../components/cardArticle";
import BlogCard from "../../components/blogCard";

export function useMedia(query) {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      console.log(media.matches);
      
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addEventListener("change", listener);

    return () => media.removeEventListener("change", listener);
  }, [matches, query]);

  return matches;
}

const AllArticlesList = () => {
  const dispatch = useContext(GlobalDispatchContext);
  const { articles, fetchArticles } = useArticles();
  const [loading, setLoading] = useState(true);

  const isPhone = useMedia("(max-width: 767px)");
  const isLaptop = useMedia("(min-width: 1225px)");

  useEffect(() => {
    setLoading(true);
    fetchArticles().finally(() => setLoading(false));
  }, []);

  //   const getAllArticlesToModerate = async () => {
  //     const data = await getAllArticles();

  //     if (!data) {
  //       return (
  //         <div className="container is-fullhd" style={{ width: "880px" }}>
  //           <h1>Pas d'évènements en cours</h1>
  //         </div>
  //       );
  //     }

  //     setArticles(data);
  //   };

  //   const GoToArticle = async (id) => {
  //     const article = await getArticleById(id);
  //     dispatch({
  //       type: "get_article",
  //       payload: article,
  //     });
  //     navigate("/app/updateArticlePage");
  //   };

  //   useEffect(() => {
  //     getAllArticlesToModerate();
  //   }, []);

  return (
    <div className="columns is-multiline">
      {articles.length !== 0 &&
        articles.map((article) => (
          <div key={article.title} className="column is-one-third">
            <BlogCard
              title={article.title}
              image={article.image}
              category={article.category}
              author={article.author}
              slug={article.slug}
              date={article.date}
              content={article.content} // Utiliser le contenu HTML généré par `marked`
              link={`updateArticle`}
            />
          </div>
        ))}
    </div>
  );
};

export default AllArticlesList;
